<template>
  <v-theme-provider dark>
    <base-section id="contact-us" class="primary" space="40">
      <v-container fluid>
        <!-- <v-simple-table
          class="primary"
          height="80%"
          width="100%"
          hide-default-footer
        >
          <tr>
            <td colspan="2" id="videoCell">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/fzmj1adX3hg?rel=0"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              >
              </iframe>
            </td>
            <td>
              <base-title align="center" title="HAVE QUESTIONS? LET US KNOW!" />
              <base-body align="center" id="rightAlign">
                <span align="center" class="white--text">
                  Get in touch with us and let us know any queries you might
                  have about the product, or about data security in general.
                </span>
              </base-body>
              <div id="rightAlign">
                <base-btn color="white" to="/contact-us" large outlined>
                  Contact Us
                </base-btn>
              </div>
            </td>
          </tr>
        </v-simple-table> -->

        <v-row>
          <v-col id="videoCell" cols="12" sm="7">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/fzmj1adX3hg?rel=0"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            >
            </iframe>
          </v-col>
          <v-col cols="12" sm="5" class="my-4 py-4">
            <div id="rightAlign">
              <base-title title="HAVE QUESTIONS? LET US KNOW!" />
              <base-body>
                <span align="center" class="white--text">
                  Get in touch with us and let us know any queries you might
                  have about the product, or about data security in general.
                </span>
              </base-body>
              <base-btn color="white" to="/contact-us" large outlined>
                Contact Us
              </base-btn>
            </div>
          </v-col>
        </v-row>

        <!-- <v-row justify="center"> 
          <v-col cols="12" md="8">
            
            <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/fzmj1adX3hg?rel=0"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          >
          </iframe>
          </v-col>
          <v-col cols="12" md="4">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              title="HAVE QUESTIONS? LET US KNOW!"
            />

            <base-body space="0">
              <span class="white--text">
                Get in touch with us and let us know any queries you might have about the product, or about data security in general.
              </span>
            </base-body>
          </v-col>
        </v-row>
        
        <v-row justify="center"> 
          
          <v-col cols="12" md="8"></v-col>
          <v-col
            cols="12"
            md="4"
          >
            <base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              to="/contact-us"
              large
              outlined
            >
              Contact Us
            </base-btn>
          </v-col>
        </v-row> -->
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
export default {
  name: "SectionContactUs",
};
</script>
<style lang="scss">
#videoCell {
  height: 30rem;
}
#rightAlign {
  align-content: center;
  align-items: center;
  align-self: center;
}
</style>